import { createMuiTheme } from '@material-ui/core'

export const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#5f42ff',
    },
    secondary: {
      main: '#EFEFF9',
    },
    background: {
      default: '#ffffff',
    },
    text: {
      primary: '#13163E',
      secondary: '#E0E0E0',
    },
  },
  props: {
    MuiPaper: {
      variant: 'outlined',
    },
    MuiTextField: {
      variant: 'outlined',
      margin: 'normal',
      fullWidth: true,
      size: 'small',
    },
    MuiButton: {
      disableElevation: true,
    },
    MuiCheckbox: {
      color: 'primary',
    },
  },
  mixins: {
    toolbar: {
      minHeight: 56,
    },
  },
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
    h1: {
      fontSize: '32px',
      lineHeight: '40px',
      fontWeight: 700,
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
    },
    h2: {
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '34px',
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
    },
    h3: {
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '24px',
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
    },
    body1: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 'inherit',
    },
    caption: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 600,
    },
    body2: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 'inherit',
    },
    subtitle1: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 'inherit',
    },
    button: {
      textTransform: 'none',
      fontWeight: 600,
    },
  },
  overrides: {
    MuiSelect: {
      root: {
        border: '2px solid #E0E0E0',
        filter: 'drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.04))',
        borderRadius: '8px !important',
      },
      selectMenu: {
        height: '15px',
        minHeight: 0,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '8px !important',
        border: 'none',
      },
      notchedOutline: {
        border: '2px solid #E0E0E0',
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: 'none',
        fontSize: '14px',
      },
      sizeSmall: {
        paddingTop: '4px',
        paddingBottom: '4px',
      },
    },
    MuiTablePagination: {
      toolbar: {
        marginTop: '16px',
        minHeight: 'fit-content',
      },
    },
    MuiPaper: {
      outlined: {
        borderRadius: '8px',
        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.04)',
      },
    },
    MuiButton: {
      root: {
        borderRadius: '8px',
        lineHeight: '24px',
        padding: '8px 12px',
        height: '40px',
        fontSize: '16px',
      },
      outlined: {
        border: '2px solid',
      },
    },
    MuiCheckbox: {
      root: {
        color: '#B8B8C9',
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: '14px',
        lineHeight: '20px',
      },
    },
    MuiInputBase: {
      input: {
        fontWeight: 600,
      },
    },
  },
})
