import { ReactNode } from 'react'
import { FormControl, InputLabel, makeStyles, Select } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  select: {
    minWidth: '120px',
  },
  settings: {
    minWidth: '140px',
  },
}))

type Props = {
  label: string
  children: ReactNode
  disabled?: boolean
  settings?: boolean
}

export const Menu = ({ disabled, children, label, settings }: Props) => {
  const classes = useStyles()

  return (
    <FormControl variant="outlined" size="small">
      <InputLabel shrink={false}>{label}</InputLabel>
      <Select
        className={clsx({
          [classes.settings]: settings,
          [classes.select]: !settings,
        })}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        autoWidth
        disabled={disabled}
        value=""
      >
        {children}
      </Select>
    </FormControl>
  )
}
