import { createLazyRoute, LazyRoute } from 'lib/lazy-routing'
import { routes } from './routes'

const lazyRoute = createLazyRoute({
  modifyTitle: title => `${title} · Native Voice`,
})

export const pageRoutesAuth: Array<LazyRoute> = [
  lazyRoute({
    title: 'My profile',
    path: routes.myProfile,
    factory: () => import(`pages/profile`),
  }),
  lazyRoute({
    title: 'Create product',
    path: routes.createProduct,
    factory: () => import(`pages/products/new`),
  }),
  lazyRoute({
    title: 'Product details',
    path: routes.productDetails,
    factory: () => import(`pages/products/[productId]`),
  }),
  lazyRoute({
    title: 'Product history',
    path: routes.productHistory,
    factory: () => import(`pages/products/[productId]`),
  }),
  lazyRoute({
    title: 'Edit product',
    path: routes.productEdit,
    factory: () => import(`pages/products/[productId]`),
  }),
  lazyRoute({
    title: 'Products',
    path: routes.products,
    factory: () => import(`pages/products`),
  }),
  lazyRoute({
    title: 'Compatibility details',
    path: routes.compatibilityTestDetails,
    factory: () => import(`pages/compatibility/[testId]`),
  }),
  lazyRoute({
    title: 'Compatibility',
    path: routes.compatibilityProduct,
    factory: () => import(`pages/compatibility/[productId]`),
  }),
  lazyRoute({
    title: 'Compatibility',
    path: routes.compatibility,
    factory: () => import(`pages/compatibility`),
  }),
  lazyRoute({
    title: 'Manage Test Files',
    path: routes.manageTestFiles,
    factory: () => import(`pages/compatibility/manage`),
  }),
  lazyRoute({
    title: 'Download',
    path: routes.download,
    factory: () => import(`pages/download`),
  }),
  lazyRoute({
    title: 'Home',
    path: routes.home,
    factory: () => import(`pages/home`),
  }),
  lazyRoute({
    title: 'Documentation',
    path: routes.documentation,
    factory: () => import(`pages/documentation`),
  }),
  lazyRoute({
    title: 'IoT Documentation',
    path: routes.documentationIoT,
    factory: () => import(`pages/documentation/IoT`),
  }),
  lazyRoute({
    title: 'Brand Documentation',
    path: routes.documentationBrand,
    factory: () => import(`pages/documentation/Brand`),
  }),
  lazyRoute({
    title: 'Internal Docs',
    path: routes.internalDocs,
    factory: () => import(`pages/internalDocs`),
  }),
  lazyRoute({
    title: 'Users List',
    path: routes.users,
    factory: () => import(`pages/users`),
  }),
  lazyRoute({
    title: 'User details',
    path: routes.userDetails,
    factory: () => import(`pages/users/[userId]`),
  }),
  lazyRoute({
    title: 'Apps details',
    path: routes.appDetails,
    factory: () => import(`pages/apps/[appId]`),
  }),
  lazyRoute({
    title: 'Add new app',
    path: routes.createApp,
    factory: () => import(`pages/apps/new`),
  }),
  lazyRoute({
    title: 'Apps list',
    path: routes.apps,
    factory: () => import(`pages/apps`),
  }),
]

export const pageRoutesUnauth: Array<LazyRoute> = [
  lazyRoute({
    title: 'Sign in',
    path: routes.login,
    factory: () => import(`pages/login`),
  }),
  lazyRoute({
    title: 'Sign up',
    path: routes.signup,
    factory: () => import(`pages/signup`),
  }),
  lazyRoute({
    title: 'Forgot password',
    path: routes.forgotPassword,
    factory: () => import(`pages/password/reset`),
  }),
  lazyRoute({
    title: 'Confirm password reset',
    path: routes.recoveryPassword,
    factory: () => import(`pages/password/recovery`),
  }),
  lazyRoute({
    title: 'Email confirmation',
    path: routes.emailConfirmation,
    factory: () => import(`pages/email`),
  }),
  lazyRoute({
    title: 'Email verification resend',
    path: routes.emailResend,
    factory: () => import(`pages/email/resend`),
  }),
]

export const allPageRoutes = [...pageRoutesAuth, ...pageRoutesUnauth]
