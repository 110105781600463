import * as t from 'io-ts'
import { optionFromNullable, DateFromISOString } from 'io-ts-types'

export const TUserScope = t.string

export const TUserScopes = t.array(TUserScope)

export const TUserRole = t.union([
  t.literal('USER'),
  t.literal('DRAFT_USER'),
  t.literal('ADMIN'),
  t.literal('SUPER_ADMIN'),
  t.literal('EMPLOYEE'),
])

export const TMyProfile = t.type({
  email: t.string,
  firstName: t.string,
  lastName: t.string,
  organization: t.string,
  address: optionFromNullable(t.string),
  organizationCreator: t.boolean,
  role: t.type({
    roleName: TUserRole,
    scopes: t.array(t.string),
  }),
})

export const TUser = t.intersection([
  TMyProfile,
  t.type({
    userId: t.string,
    unreviewedTestsCount: optionFromNullable(t.number),
  }),
])

export const TLoginResult = t.type({
  accessToken: t.string,
  expires: t.number,
  user: TMyProfile,
})

export const TRefreshResult = t.type({
  accessToken: t.string,
  expires: t.number,
  user: TMyProfile,
})

export const TUsersResult = t.array(TUser)

export const TSignupResult = t.type({
  email: t.string,
  firstName: t.string,
  lastName: t.string,
  organizationCreator: t.boolean,
  role: t.type({
    roleName: t.string,
  }),
})

const TCompatibilityProductStatus = t.union([
  t.literal('UNATTEMPTED'),
  t.literal('PENDING'),
  t.literal('COMPATIBLE'),
  t.literal('REQUIRES_ATTENTION'),
])

export const TProductRow = t.type({
  productId: t.string,
  name: t.string,
  compatibilityStatus: TCompatibilityProductStatus,
  createdAt: t.string,
  identifier: t.string,
  category: t.string,
  amazonCertificationNumber: optionFromNullable(t.string),
})

const TCompatibilityTestStatus = t.union([
  t.literal('UNATTEMPTED'),
  t.literal('PENDING'),
  t.literal('PASSED'),
  t.literal('FAILED'),
])

const TCompatibilityTestType = t.union([
  t.literal('SOFTWARE'),
  t.literal('HARDWARE'),
  t.literal('APP_TOOL'),
])

export const TProductRows = t.array(TProductRow)

export const TSubmissionHistoriesItem = t.type({
  status: TCompatibilityTestStatus,
  comment: optionFromNullable(t.string),
  userFileId: optionFromNullable(t.string),
  userFileName: optionFromNullable(t.string),
  adminFileId: optionFromNullable(t.string),
  adminFileName: optionFromNullable(t.string),
  updatedAt: DateFromISOString,
  createdAt: DateFromISOString,
})

export const TSubmissionHistories = t.array(TSubmissionHistoriesItem)

export const TCompatibilityTestItem = t.type({
  compatibilityTestId: t.number,
  fileId: optionFromNullable(t.string),
  fileName: optionFromNullable(t.string),
  description: optionFromNullable(t.string),
  compatibilityTestType: TCompatibilityTestType,
})

export const TCompatibilityTest = t.type({
  productId: t.string,
  compatibilityProductTestId: t.string,
  productName: t.string,
  compatibilityTest: TCompatibilityTestItem,
  status: TCompatibilityTestStatus,
  updatedAt: DateFromISOString,
  createdAt: DateFromISOString,
  submissionHistories: TSubmissionHistories,
})

export const TCompatibilityTests = t.array(TCompatibilityTest)

export const TmacAddress = t.type({
  leftLimit: t.string,
  rightLimit: t.string,
})

export const TAssociatedProduct = t.type({
  id: t.string,
  name: t.string,
  associated: t.boolean,
})

export const TAssociatedApp = t.type({
  appId: t.string,
  name: t.string,
})

export const TProduct = t.type({
  voiceCommandInstructions: optionFromNullable(t.string),
  productId: t.string,
  vendorId: optionFromNullable(t.string),
  modelId: optionFromNullable(t.string),
  name: t.string,
  identifier: t.string,
  compatibilityStatus: t.string,
  category: t.string,
  amazonCertificationNumber: optionFromNullable(t.string),
  createdAt: t.string,
  userId: t.string,
  macAddresses: t.array(TmacAddress),
  associatedApps: t.array(TAssociatedApp),
  description: t.string,
  commercially: t.boolean,
  targetAnnualVolume: optionFromNullable(t.string),
  launchTimeline: optionFromNullable(t.string),
  certifiedWithAmazon: t.boolean,
  comment: optionFromNullable(t.string),
  imageId: optionFromNullable(t.string),
  productTests: TCompatibilityTests,
  imageName: optionFromNullable(t.string),
  androidToneStartDelay: t.union([t.number, t.undefined]),
  bluetoothName: optionFromNullable(t.string),
  bleName: optionFromNullable(t.string),
  triggerWay: optionFromNullable(t.string),
})

export const TCompatibilityProducts = t.array(
  t.type({
    productId: t.string,
    name: t.string,
  }),
)

const TProductCompatibilityTest = t.type({
  compatibilityTestId: t.number,
  fileId: t.string,
  description: t.string,
  compatibilityTestType: t.string,
  updatedAt: DateFromISOString,
  createdAt: t.string,
})

const TPendingTestsItem = t.type({
  productId: t.string,
  productName: t.string,
  userId: t.string,
  userFullName: t.string,
  compatibilityProductTestId: t.string,
  compatibilityTest: TProductCompatibilityTest,
  status: TCompatibilityTestStatus,
  createdAt: DateFromISOString,
})

export const TPendingTestsList = t.array(TPendingTestsItem)

export const TTicket = t.type({
  ticketId: t.number,
  issueNumber: t.string,
  issueType: t.string,
  productName: t.string,
  dateCreated: t.string,
  status: t.keyof({
    NEW: null,
    READ: null,
  }),
})

export const TTickets = t.type({
  total: t.number,
  rows: t.array(TTicket),
})

export const TTableHeaders = t.type({
  count: t.string,
  next: t.string,
})

const TAndroidVersion = t.type({
  gradle: t.string,
  version: t.string,
  notes: t.array(t.string),
})

const TIosVersion = t.type({
  path: t.string,
  version: t.string,
  pod: t.string,
  notes: t.array(t.string),
})

export const TAndroidSdkInfo = t.type({
  configuration: t.string,
  description: t.string,
  sdkType: t.string,
  versions: t.array(TAndroidVersion),
})

export const TIosSdkInfo = t.type({
  configuration: t.string,
  description: t.string,
  sdkType: t.string,
  versions: t.array(TIosVersion),
})

export const TPersonalEmail = t.type({
  existMark: t.boolean,
  newOrganization: t.boolean,
})

export const TTestFile = t.type({
  compatibilityTestId: t.number,
  compatibilityTestType: TCompatibilityTestType,
  createdAt: DateFromISOString,
  description: t.string,
  fileId: t.string,
  fileName: t.string,
  updatedAt: DateFromISOString,
})

export const TTestFiles = t.array(TTestFile)

const TPlatformType = t.union([t.literal('IOS'), t.literal('ANDROID')])

export const TAppResponse = t.type({
  appId: t.string,
  createdAt: DateFromISOString,
  creatorId: t.string,
  appPublicKey: t.string,
  name: t.string,
  packageName: t.string,
  platform: TPlatformType,
  associatedBetaProducts: t.array(TAssociatedProduct),
  associatedReleaseProducts: t.array(TAssociatedProduct),
})

export const TAppItem = t.type({
  appId: t.string,
  appPublicKey: t.string,
  associatedBetaProducts: t.array(TAssociatedProduct),
  associatedReleaseProducts: t.array(TAssociatedProduct),
  createdAt: t.string,
  creatorId: t.string,
  name: t.string,
  packageName: t.string,
  platform: TPlatformType,
  usersCount: t.number,
})

export const TAppsList = t.array(TAppItem)

export const TJFrogCredentials = t.type({
  username: t.string,
  jfrogPassword: t.union([t.string, t.undefined]),
})
