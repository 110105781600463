import { useCallback, useEffect, useRef, useState } from 'react'
import constate from 'constate'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { env } from 'app/env'
import { TRefreshResult } from './codecs'

export const useLogoutAcrossTabs = (callback: () => void) => {
  useEffect(() => {
    const syncLogout = (event: StorageEvent) => {
      if (event.key === 'logout') {
        callback()
      }
    }
    window.addEventListener('storage', syncLogout)
    return () => {
      window.removeEventListener('storage', syncLogout)
    }
  }, [callback])

  const logout = useCallback(() => {
    callback()
    localStorage.setItem('logout', Date.now().toString())
  }, [callback])

  return logout
}

type AuthState =
  | { type: 'initial' }
  | {
      type: 'authenticated'
      accessToken: string
      scopes: Array<string>
    }
  | { type: 'unauthenticated' }

const useAuth = () => {
  const [auth, setAuth] = useState<AuthState>({
    type: 'initial',
  })

  const $refresh = useMutation(
    'POST',
    '/api/auth/tokens/refresh',
    TRefreshResult,
  )

  const timeoutRef = useRef<number | null>(null)

  const setAuthData = useCallback(
    (accessToken: string, expiresInSeconds: number, scopes: Array<string>) => {
      setAuth({ type: 'authenticated', accessToken, scopes })
      timeoutRef.current = window.setTimeout(() => {
        timeoutRef.current = null
        refresh()
      }, (expiresInSeconds - 60) * 1000)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const removeToken = useLogoutAcrossTabs(
    useCallback(() => {
      setAuth({ type: 'unauthenticated' })
      if (timeoutRef.current !== null) {
        window.clearTimeout(timeoutRef.current)
      }
    }, []),
  )

  const mutateRefresh = $refresh.mutate
  const resetRefresh = $refresh.reset

  const refresh = useCallback(() => {
    mutateRefresh(
      {
        body: {
          clientId: env.REACT_APP_APPLICATION_ID,
        },
      },
      {
        onSuccess: ({ data }) => {
          const { user, accessToken, expires } = data
          resetRefresh()
          setAuthData(accessToken, expires, user.role.scopes)
        },
        onFailure: () => {
          removeToken()
        },
      },
    )
  }, [mutateRefresh, setAuthData, resetRefresh, removeToken])

  useEffect(() => {
    refresh()
  }, [refresh])

  return {
    auth,
    setAuthData,
    removeToken,
  } as const
}

export const [AuthProvider, useAuthContext] = constate(useAuth)
