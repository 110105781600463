import 'styles/index.css'
import { StrictMode, ComponentType } from 'react'
import { CssBaseline, MuiThemeProvider } from '@material-ui/core'
import { muiTheme } from 'app/theme'
import { BrowserRouter } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { SnackbarProvider } from 'notistack'
import { SWRConfig } from 'swr'
import Pace from 'components/Pace'
import { AuthProvider } from 'app/auth'

type Props = {
  Component: ComponentType
}

const App = ({ Component }: Props) => {
  return (
    <StrictMode>
      <MuiThemeProvider theme={muiTheme}>
        <CssBaseline />
        <Pace color={muiTheme.palette.primary.light} />
        <ErrorBoundary fallbackRender={() => <div>Runtime error.</div>}>
          <SnackbarProvider>
            <SWRConfig value={{}}>
              <BrowserRouter>
                <AuthProvider>
                  <Component />
                </AuthProvider>
              </BrowserRouter>
            </SWRConfig>
          </SnackbarProvider>
        </ErrorBoundary>
      </MuiThemeProvider>
    </StrictMode>
  )
}

export default App
