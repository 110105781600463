import { useAuthContext } from 'app/auth'
import { isLeft } from 'fp-ts/Either'
import { Mixed, TypeOf } from 'io-ts'
import { generatePath, ExtractRouteParams } from 'react-router'
import useMutationBase from 'use-mutation'
import {
  concatQueryParams,
  MutationError,
  RestMethod,
  throwError,
} from './common'

type Body = { [key: string]: any }

type MutationType = 'json' | 'file'

const createBody = (
  body: Body | undefined,
  type: MutationType,
): string | FormData | undefined => {
  if (body === undefined) {
    return undefined
  }

  if (type === 'json') {
    return JSON.stringify(body)
  }

  if (type === 'file') {
    const formData = new FormData()

    Object.entries(body).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(file => formData.append(key, file))
      } else {
        formData.append(key, value)
      }
    })

    return formData
  }
}

export const useMutation = <
  Path extends string,
  C extends Mixed | undefined = undefined
>(
  method: RestMethod,
  url: Path,
  codec?: C,
  type: MutationType = 'json',
) => {
  const { auth, removeToken } = useAuthContext()
  const token = auth?.type === 'authenticated' ? auth.accessToken : null
  type RouteParams = ExtractRouteParams<Path>

  type Input = keyof RouteParams extends never
    ? {
        params?: undefined
        body?: Body
        search?: URLSearchParams
      }
    : {
        params: ExtractRouteParams<Path>
        body?: Body
        search?: URLSearchParams
      }

  const [mutate, state] = useMutationBase<
    Input,
    C extends Mixed ? TypeOf<C> : null,
    MutationError
  >(async ({ params, body, search }) => {
    const headers = new Headers()

    if (type === 'json') {
      headers.set('Content-Type', 'application/json')
    }

    if (token !== null) {
      headers.set('Authorization', `Bearer ${token}`)
    }

    const fullUrl = concatQueryParams(generatePath(url, params), search)

    try {
      const res = await window.fetch(fullUrl, {
        method,
        body: createBody(body, type),
        headers,
      })

      if (res.status === 400 || res.status === 401 || res.status === 500) {
        try {
          const json = await res.json()

          if (
            json.code === 'error_token_expired' ||
            json.code === 'error_invalid_token' ||
            json.code === 'error_token_generation'
          ) {
            removeToken()
          }
          if (json.code === 'error_incorrect_credentials') {
            throwError({ type: 'client_error', errorCode: json.code })
          }
          if (json.code === 'error_duplicated_email') {
            throwError({ type: 'client_error', errorCode: json.code })
          }
        } catch (error) {
          console.log(error)

          if (error.type === undefined) {
            throwError({
              type: 'client_error',
              errorCode: 'Something went wrong with authentication',
            })
          } else {
            return throwError(error)
          }
        }
      }

      if (res.status >= 500) {
        throwError({ type: 'server_error', status: res.status })
      }

      if (res.status === 404) {
        throwError({ type: 'not_found' })
      }

      try {
        if (res.status >= 400) {
          const json = await res.json()
          throwError({ type: 'client_error', errorCode: json.code })
        }

        if (codec === undefined) {
          return null
        }

        const json: unknown = await res.json()

        const decodedJson = codec.decode(json)

        if (isLeft(decodedJson)) {
          return throwError({ type: 'failed_to_decode_json', json })
        }

        return decodedJson.right
      } catch (error) {
        if (error.type === undefined) {
          throwError({ type: 'failed_to_parse_json', response: res })
        }
        throw error
      }
    } catch (error) {
      if (error.type === undefined) {
        throwError({ type: 'network_error', error })
      }
      throw error
    }
  })

  return {
    mutate,
    ...state,
  }
}
