import { Box, makeStyles, Toolbar } from '@material-ui/core'
import { ReactNode } from 'react'

const useStyles = makeStyles(theme => ({
  content: {
    minHeight: '100vh',
    maxWidth: '100vw',
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,

    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
  },
}))

type Props = {
  children: ReactNode
}

export const AppContent = (props: Props) => {
  const classes = useStyles()

  return (
    <main className={classes.content}>
      <Toolbar />
      <Box flex={1} display="flex" flexDirection="column">
        {props.children}
      </Box>
    </main>
  )
}
