import { strict } from 'io-ts'
import { flow } from 'fp-ts/function'
import { getOrElseW, mapLeft } from 'fp-ts/Either'
import { formatValidationErrors } from 'io-ts-reporters'

const throwHumanReadableErrors = (errors: Array<string>): never => {
  const error = new Error(`\n${errors.map(error => `❗ ${error}`).join('\n')}`)
  error.name = 'EnvironmentVariablesDecodeError'
  throw error
}

export const getEnvVariables = flow(
  strict,
  Codec => Codec.decode(process.env),
  mapLeft(formatValidationErrors),
  getOrElseW(throwHumanReadableErrors),
)
