import { Portal, Typography } from '@material-ui/core'
import { createContext, ReactNode, useContext } from 'react'

export const AppScreenTitleContext = createContext<HTMLDivElement | null>(null)

type Props = {
  children: ReactNode
}

export const AppScreenTitle = (props: Props) => {
  const appScreenTitleContainer = useContext(AppScreenTitleContext)

  return (
    <Portal container={appScreenTitleContainer}>
      <Typography variant="h6">{props.children}</Typography>
    </Portal>
  )
}
