import {
  SwipeableDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
  Hidden,
  makeStyles,
  Toolbar,
  useTheme,
  useMediaQuery,
} from '@material-ui/core'
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import DescriptionIcon from '@material-ui/icons/Description'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import GroupIcon from '@material-ui/icons/Group'
import { useLocation } from 'react-router-dom'
import { routes } from 'app/routes'
import { useTranslation } from 'react-i18next'
import { LinkPrefetch } from 'components/InternalLink/InternalLink'
import { usePermissions } from 'app/permissions'

export const drawerWidth = 250

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    width: drawerWidth,
    border: 'none',
    boxShadow: 'none',

    '&:hover': {
      '&& .DrawerContent::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.grey[300],
      },
    },
  },
  content: {
    paddingBottom: theme.spacing(6),
    overflowY: 'overlay' as any,

    '&::-webkit-scrollbar': {
      width: '8px',
    },

    '&::-webkit-scrollbar-track': {
      background: '#fff',
    },

    '&::-webkit-scrollbar-thumb': {
      background: '#fff',
    },
  },
}))

const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent)

type Props = {
  variant: 'temporary' | 'persistent'
  open: boolean
  onOpen: () => void
  onClose: () => void
}

export const AppDrawer = (props: Props) => {
  const { t } = useTranslation()

  const classes = useStyles()

  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  const location = useLocation()

  const pathnameStart = `/${location.pathname.split('/')[1]}`

  const { canAdminUserManagement, canScopeGetInternalDocs } = usePermissions()

  return (
    <SwipeableDrawer
      open={props.open}
      onOpen={props.onOpen}
      onClose={props.onClose}
      variant={props.variant}
      ModalProps={{ keepMounted: true }}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Hidden smDown>
        <Toolbar />
      </Hidden>
      <div className={`${classes.content} DrawerContent`}>
        <Box>
          <Hidden smUp>
            <List>
              <ListItem
                button
                component={LinkPrefetch}
                to={routes.myProfile}
                onClick={smDown ? props.onClose : undefined}
              >
                <ListItemText primary="Account settings" />
              </ListItem>
            </List>
            <Divider />
          </Hidden>
          <List>
            <ListItem
              button
              component={LinkPrefetch}
              to={routes.home}
              onClick={smDown ? props.onClose : undefined}
              selected={pathnameStart === routes.home}
            >
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem
              button
              component={LinkPrefetch}
              to={routes.documentation}
              onClick={smDown ? props.onClose : undefined}
              selected={pathnameStart === routes.documentation}
            >
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary={t('navigation.documentation')} />
            </ListItem>
            {canAdminUserManagement && (
              <ListItem
                button
                component={LinkPrefetch}
                to={routes.users}
                onClick={smDown ? props.onClose : undefined}
                selected={pathnameStart === routes.users}
              >
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary={t('navigation.users')} />
              </ListItem>
            )}
            <ListItem
              button
              component={LinkPrefetch}
              to={routes.products}
              onClick={smDown ? props.onClose : undefined}
              selected={pathnameStart === routes.products}
            >
              <ListItemIcon>
                <HeadsetMicIcon />
              </ListItemIcon>
              <ListItemText primary={t('navigation.products')} />
            </ListItem>
            <ListItem
              button
              component={LinkPrefetch}
              to={routes.download}
              onClick={smDown ? props.onClose : undefined}
              selected={pathnameStart === routes.download}
            >
              <ListItemIcon>
                <CloudDownloadIcon />
              </ListItemIcon>
              <ListItemText primary={t('navigation.download')} />
            </ListItem>
            <ListItem
              button
              component={LinkPrefetch}
              to={routes.apps}
              onClick={smDown ? props.onClose : undefined}
            >
              <ListItemIcon>
                <CloudDownloadIcon />
              </ListItemIcon>
              <ListItemText primary={t('navigation.apps')} />
            </ListItem>
            {canScopeGetInternalDocs && (
              <ListItem
                button
                component={LinkPrefetch}
                to={routes.apps}
                onClick={smDown ? props.onClose : undefined}
              >
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary={t('navigation.internal')} />
              </ListItem>
            )}
          </List>
          <Hidden smUp>
            <Divider />
            <List>
              <ListItem button to={routes.logout} component={LinkPrefetch}>
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary="Log out" />
              </ListItem>
            </List>
          </Hidden>
        </Box>
      </div>
    </SwipeableDrawer>
  )
}
