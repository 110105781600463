export const allScopes = [
  'scope-get-own-profile',
  'scope-update-own-profile',
  'create-product',
  'get-product-list',
  'get-product',
  'update-product',
  'remove-products',
  'duplicate-products',
  'admin-file-management',
  'compatibility-test-management',
  'admin-compatibility-product-test-management',
  'compatibility-product-test-management',
  'admin-user-management',
  'get-sdk-information',
  'download-sdk',
  'docs-file-management',
  'scope-app-management',
  'scope-admin-app-management',
  'scope-super-admin-remove-jfrog-user',
  'scope-get-own-jfrog-credentials',
  'scope-get-internal-docs',
] as const
