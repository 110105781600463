import { ChangeEvent, ReactNode, useState } from 'react'
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  makeStyles,
  MenuItem,
  Toolbar,
  useMediaQuery,
  useTheme,
  Tabs,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { routes } from 'app/routes'
import { drawerWidth } from './AppDrawer'
import logo from './native-voice.png'
import { LinkPrefetch } from 'components/InternalLink/InternalLink'
import { Menu as SelectMenu } from 'components/Menu'
import { useTranslation } from 'react-i18next'
import { Tab } from '@material-ui/core'
import { usePermissions } from 'app/permissions'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    padding: `0 ${theme.spacing(4)}px`,
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.04)',
  },
  nav: {
    display: 'flex',
    flexDirection: 'row',
  },
  tabs: {
    display: 'flex',
    flex: 1,
    minHeight: 'auto',
    '& .Mui-selected': {
      fontWeight: 600,
    },
  },
  tab: {
    textTransform: 'initial',
    zIndex: 1,
    minWidth: 'auto',
    minHeight: 'auto',
    fontWeight: 400,
    fontSize: '16px',
    opacity: 1,
  },
  arrowIcon: {
    color: '#B8B8C9',
    marginLeft: '10px',
  },
}))

type Props = {
  screenTitleContainer: ReactNode
  onMenuOpen: () => void
  onClose: () => void
}

export const AppTopBar = (props: Props) => {
  const classes = useStyles()

  const location = useLocation()
  const pathnameStart = `/${location.pathname.split('/')[1]}`

  const menuItems = [
    routes.home,
    routes.documentation,
    routes.users,
    routes.products,
    routes.apps,
    routes.compatibility,

    routes.download,
    routes.internalDocs,
  ]

  const notMenuItem = !menuItems.find(item => item === pathnameStart)

  const { t } = useTranslation()
  const [tabValue, setTabValue] = useState<string | false>(
    notMenuItem ? false : pathnameStart,
  )

  const { canAdminUserManagement, canScopeGetInternalDocs } = usePermissions()

  const theme = useTheme()
  const offset = canScopeGetInternalDocs ? 122 : 0
  const widthToHide = offset + (canAdminUserManagement ? 838 : 738)
  const isHiddenTabs = useMediaQuery(theme.breakpoints.down(widthToHide))

  const handleChangeTabs = (event: ChangeEvent<{}>, value: string) => {
    setTabValue(value)
  }

  useEffect(() => {
    setTabValue(notMenuItem ? false : pathnameStart)
  }, [notMenuItem, pathnameStart])

  return (
    <AppBar
      position="fixed"
      className={classes.appBar}
      color="inherit"
      elevation={0}
      variant="elevation"
    >
      <Toolbar disableGutters>
        {isHiddenTabs && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={props.onMenuOpen}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Hidden mdDown>
          <Box
            width={`${drawerWidth - 76}px`}
            display="flex"
            alignItems="center"
          >
            <Link to={routes.index}>
              <img src={logo} alt="Native voice" width="152px" />
            </Link>
          </Box>
        </Hidden>
        {!isHiddenTabs && (
          <Tabs
            className={classes.tabs}
            value={tabValue}
            onChange={handleChangeTabs}
            indicatorColor="primary"
            centered
          >
            <Tab
              label="Home"
              className={classes.tab}
              component={LinkPrefetch}
              to={routes.home}
              value={routes.home}
            />
            <Tab
              label={t('navigation.documentation')}
              className={classes.tab}
              component={LinkPrefetch}
              to={routes.documentation}
              value={routes.documentation}
            />
            {canAdminUserManagement && (
              <Tab
                label={t('navigation.users')}
                className={classes.tab}
                component={LinkPrefetch}
                to={routes.users}
                value={routes.users}
              />
            )}
            <Tab
              label={t('navigation.products')}
              className={classes.tab}
              component={LinkPrefetch}
              to={routes.products}
              value={routes.products}
            />
            <Tab
              label={t('navigation.apps')}
              className={classes.tab}
              component={LinkPrefetch}
              to={routes.apps}
              value={routes.apps}
            />
            <Tab
              label={t('navigation.download')}
              className={classes.tab}
              component={LinkPrefetch}
              to={routes.download}
              value={routes.download}
            />
            {canScopeGetInternalDocs && (
              <Tab
                label={t('navigation.internal')}
                className={classes.tab}
                component={LinkPrefetch}
                to={routes.internalDocs}
                value={routes.internalDocs}
              />
            )}
          </Tabs>
        )}

        {!isHiddenTabs && (
          <Box>
            <SelectMenu label="My account" settings>
              <MenuItem
                component={LinkPrefetch}
                to={{
                  pathname: routes.myProfile,
                  state: { prevPath: location.pathname },
                }}
              >
                Account settings
              </MenuItem>
              <MenuItem component={LinkPrefetch} to={routes.logout}>
                Log out
              </MenuItem>
            </SelectMenu>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  )
}
