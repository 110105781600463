import { useMemo } from 'react'
import { capitalize } from '@material-ui/core'
import { useAuthContext } from 'app/auth'
import { allScopes } from './scopes'

/**
 * Transforms `snake_case_type` to `camelCaseType`
 */

const toCamelCase = (scope: string): string => {
  return scope.split('-').map(capitalize).join('')
}

type Separator = '-'

export type CamelCase<
  T extends string
> = T extends `${Separator}${infer Suffix}`
  ? CamelCase<Suffix>
  : T extends `${infer Prefix}${Separator}`
  ? CamelCase<Prefix>
  : T extends `${infer Prefix}${Separator}${infer Suffix}`
  ? CamelCase<`${Prefix}${Capitalize<Suffix>}`>
  : T

/**
 * Returns a list of scopes that belong to the user.
 */

const useScopes = (): Array<string> => {
  const { auth } = useAuthContext()
  if (auth.type === 'authenticated') {
    const { scopes } = auth
    return scopes
  } else return []
}

export type TransformedPermissionKey = `can${Capitalize<
  CamelCase<typeof allScopes[number]>
>}`
type PermissionMapping = Record<TransformedPermissionKey, boolean>

/**
 * Returns a mapping in which keys are all possible scopes in the system
 * and values are boolean values meaning `true` if the user has a corresponding scope.
 */

const createPermissionMapping = (
  userScopes: Array<string>,
): PermissionMapping => {
  const result: Record<string, boolean> = {}
  for (const scope of allScopes) {
    result[`can${toCamelCase(scope)}`] = userScopes.includes(scope)
  }
  return result as PermissionMapping
}

export const usePermissions = (): PermissionMapping => {
  const myScopes = useScopes()
  return useMemo(() => {
    return createPermissionMapping(myScopes)
  }, [myScopes])
}
