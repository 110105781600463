import { ReactNode, useEffect, useState } from 'react'
import { Box, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import { AppTopBar } from './AppTopBar'
import { AppScreenTitleContext } from './AppScreenTitle'
import { AppContent } from './AppContent'
import { AppDrawer } from './AppDrawer'

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.background.default,
    },
  },
}))

type Props = {
  children: ReactNode
}

export const AppLayout = (props: Props) => {
  useStyles()

  const [
    appScreenTitleRef,
    setAppScreenTitleRef,
  ] = useState<HTMLDivElement | null>(null)

  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  const [drawerOpen, setDrawerOpen] = useState(!smDown)

  useEffect(() => {
    setDrawerOpen(!smDown)
  }, [smDown])

  return (
    <AppScreenTitleContext.Provider value={appScreenTitleRef}>
      <Box display="flex">
        <AppTopBar
          screenTitleContainer={<div ref={setAppScreenTitleRef} />}
          onMenuOpen={() => setDrawerOpen(open => !open)}
          onClose={() => setDrawerOpen(false)}
        />

        {smDown && (
          <AppDrawer
            variant={'temporary'}
            open={drawerOpen}
            onOpen={() => setDrawerOpen(true)}
            onClose={() => setDrawerOpen(false)}
          />
        )}

        <AppContent>{props.children}</AppContent>
      </Box>
    </AppScreenTitleContext.Provider>
  )
}
