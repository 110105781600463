export type RestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'OPTIONS'

export type FatalError =
  | { type: 'network_error'; error: TypeError }
  | { type: 'failed_to_decode_json'; json: unknown }
  | { type: 'failed_to_parse_json'; response: Response }
  | { type: 'server_error'; status: number }
  | { type: 'failed_to_decode_headers'; headers: Headers }

export type MutationError =
  | FatalError
  | { type: 'not_found' }
  | { type: 'client_error'; errorCode: string }

export const throwError = (error: MutationError) => {
  throw error
}

export const concatQueryParams = (
  url: string,
  params?: URLSearchParams,
): string => {
  if (params === undefined) {
    return url
  }
  const paramsString = params.toString()
  return paramsString.length === 0 ? url : `${url}?${paramsString}`
}
