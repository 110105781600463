import { pageRoutesAuth, pageRoutesUnauth } from 'app/pages'
import { routes } from 'app/routes'
import { createRoutes } from 'lib/lazy-routing'
import { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Redirect, Route, Switch } from 'react-router-dom'
import { RuntimeErrorDialog } from 'components/RuntimeErrorDialog'
import { AppLayout } from 'components/AppLayout/AppLayout'
import { NotFound } from 'components/NotFound'
import { useAuthContext } from 'app/auth'
import { Logout } from './logout'
import { usePermissions } from 'app/permissions'

const PageIndex = () => {
  const { canAdminUserManagement } = usePermissions()
  const redirect = routes.home

  const { auth } = useAuthContext()

  if (auth.type === 'initial') {
    return null
  }

  if (auth.type === 'unauthenticated') {
    return (
      <Suspense fallback={null}>
        <Switch>
          {createRoutes(pageRoutesUnauth)}
          <Redirect to={routes.login} />
        </Switch>
      </Suspense>
    )
  }

  return (
    <AppLayout>
      <ErrorBoundary FallbackComponent={RuntimeErrorDialog}>
        <Suspense fallback={null}>
          <Switch>
            <Route exact path={routes.logout} component={Logout} />
            {!canAdminUserManagement && (
              <Redirect from={routes.users} to={redirect} />
            )}
            {createRoutes(pageRoutesAuth)}
            <Redirect exact from={routes.index} to={redirect} />
            <Redirect exact from={routes.login} to={redirect} />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </AppLayout>
  )
}

export default PageIndex
