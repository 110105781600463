import ReactDOM from 'react-dom'
import { reportWebVitals } from 'app/report-web-vitals'
import { initI18n } from 'app/i18n'
import { env } from 'app/env'
import App from './pages/_app'
import PageIndex from './pages'

async function main() {
  await initI18n({
    debug:
      env.REACT_APP_STAGE === 'development' &&
      env.REACT_APP_DEBUG_I18N === 'on',
  })

  if (
    process.env.NODE_ENV === 'development' &&
    (process.env.REACT_APP_MSW === 'on' ||
      process.env.REACT_APP_MSW === 'quiet')
  ) {
    const { worker } = await import('./mocks/browser')
    await worker.start({ quiet: process.env.REACT_APP_MSW === 'quiet' })
  }

  ReactDOM.render(
    <App Component={PageIndex} />,
    document.getElementById('root'),
  )
}

main()

if (env.REACT_APP_REPORT_WEB_VITALS === 'on') {
  reportWebVitals(console.log)
}
