export const routes = {
  index: '/',
  agreement: 'user-agreement',
  login: '/login',
  signup: '/signup',
  logout: '/logout',
  users: '/users',
  userDetails: '/users/:userId',
  forgotPassword: '/password/reset',
  recoveryPassword: '/password/recovery',
  emailConfirmation: '/email',
  emailResend: '/email/resend',
  myProfile: '/profile',
  products: '/products',
  productHistory: '/products/:productId/history',
  productDetails: '/products/:productId/details',
  productEdit: '/products/:productId/edit',
  createProduct: '/products/new',
  compatibility: '/compatibility',
  manageTestFiles: '/compatibility/manage',
  compatibilityProduct: '/compatibility/product/:productId',
  compatibilityTestDetails: '/compatibility/test/:productId/:testType',
  download: '/download',
  documentation: '/documentation',
  documentationIoT: '/documentation/IoT',
  documentationBrand: '/documentation/Brand',
  home: '/home',
  supportTicketDetails: '/support/ticket/:ticketId',
  createSupportTicket: '/support/ticket/new',
  faq: '/faq',
  apps: '/apps',
  appDetails: '/apps/app/:appId',
  createApp: '/apps/new',
  internalDocs: '/internaldocs',
} as const
